let pl = {
  translations: {
    "CookiesToastr.header": "Ciasteczka",
    "CookiesToastr.desc": "Strona używa ciasteczek, aby zapewnić najlepsze doświadczenie. Kliknij tutaj, aby zamknąć powiadomienie.",
    "FirstTab.label":"O nas",
    "FirstTab.firstParagraph": "Firma TELMI wyróżnia się na rynku wiedzą i doświadczeniem.",
    "FirstTab.secondParagraph": "Nasi pracownicy to osoby wykwalifikowane oraz posiadające wieloletnią praktykę, związaną z obsługą klienta i serwisem urządzeń elektronicznych. Mimo to stale podnosimy nasze kompetencje dostosowując sie do warunków panujących na rynku.",
    "FirstTab.thirdParagraph": "Nasze przedsiebiorstwo bazuje na indywidualnym podejściu do każdej sprawy, co bezpośrednio przekłada się na zadwolenie naszych klientów, których stale przybywa.",
    "FirstTab.fourthParagraph": "Naszym fundamentem jest solidność i niezawodnosc. Chcemy dać Ci możliwość skorzystania z profesjonalnej usługi.",
    "FirstTab.fifthParagraph": "Mamy swoja filozofię: oferujemy naszą wiedzę, pasję, zaangażwanie, tworzymy przyjazną atmosferę, ponieważ najważniejsza jest dla nas satysfakcja naszych klientow.",
    "FirstTab.sixthParagraph": "Zapraszamy Cię do naszego świata pasjonatów elektroniki.",
    "FirstTab.goToContact": "Kliknij tutaj, aby się z nami skontaktować",
    "SecondTab.label":"Serwis",
    "SecondTab.content":"Tutaj będzie serwis",
    "SecondTab.software":"Instalacja oprogramowania",
    "SecondTab.screen":"Wymiana wyświetlaczy",
    "SecondTab.shield":"Szkła ochronne",
    "SecondTab.audio":"Wymiana audio",
    "SecondTab.usb":"Wymiana gniazd USB",
    "SecondTab.simlock":"Usuwanie blokad",
    "ThirdTab.content":"Tutaj będą akcesoria",
    "ThirdTab.label":"Akcesoria",
    "ThirdTab.batteryLabel": "Zasilanie",
    "ThirdTab.audioLabel": "Audio",
    "ThirdTab.caseLabel": "Etui",
    "ThirdTab.pcPeripheralsLabel": "Peryferia PC",
    "ThirdTab.memoryLabel": "Pamięć",
    "ThirdTab.individualOrderLabel": "Zamówienie indywidualne",
    "ThirdTab.batteryDesc": "Oferujemy baterie do telefonów i laptopów. Zasilacze i ładowarki USB.",
    "ThirdTab.audioDesc": "Głośniki i słuchawki, zarówno do telefonów jak i innych urządzeń.",
    "ThirdTab.pcPeripheralsDesc": "W naszej ofercie znajdują się również klawiatury, myszki oraz inne urządzenia do PC.",
    "ThirdTab.memoryDesc": "Prowadzimy sprzedaż kart pamięci, dysków zewnętrznych, pendrive itp.",
    "ThirdTab.caseDesc": "W naszym sklepie znajdziesz także szeroki wybor etui, które bedzie dopasowane do Twojego telefonu oraz Twoich potrzeb.",
    "ThirdTab.individualOrderDesc": "Jeżeli szukasz czegoś specyficznego czego nie mamy w ofercie, zapraszamy do kontaktu. Postaramy się znaleźć i zamówić to czego potrzebujesz.",
    "FourthTab.label":"Galeria",
    "FourthTab.content":"Tutaj będzie galeria",
    "FifthTab.label":"Kontakt",
    "FifthTab.closed": "Nieczynne",
    "FifthTab.content":"Tutaj będą dane adresowe",
    "FifthTab.monday": "Poniedziałek",
    "FifthTab.tuesday": "Wtorek",
    "FifthTab.wednesday": "Środa",
    "FifthTab.thursday": "Czwartek",
    "FifthTab.friday": "Piątek",
    "FifthTab.saturday": "Sobota",
    "FifthTab.sunday": "Niedziela",
    "FifthTab.workTime": "Czas pracy"
}
};

export default pl;