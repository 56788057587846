import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

function  TelmiDesc() {
        const { t } = useTranslation();
        return (      
        <div>
            <p id="firstParagraph">{t('FirstTab.firstParagraph')}</p>
            <p id="secondParagraph">{t('FirstTab.secondParagraph')}</p>
            <p id="thirdParagraph">{t('FirstTab.thirdParagraph')}</p>
            <p id="fourthParagraph">{t('FirstTab.fourthParagraph')}</p>
            <p id="fifthParagraph">{t('FirstTab.fifthParagraph')}</p>
            <p id="sixthParagraph">{t('FirstTab.sixthParagraph')}</p>
        </div>);
}

export default withTranslation("translate")(TelmiDesc);