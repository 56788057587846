import React, { Component } from 'react'
import SinglePhotoCard from './SinglePhotoCard.js';
import {withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import AndroidIcon from '@material-ui/icons/Android';
import batteryImg from "../../assets/accessories/pexels-tyler-lastovich-719399.jpg";
import audioImg from "../../assets/accessories/pexels-cottonbro-6686442.jpg";
import pcImg from "../../assets/accessories/pexels-vojtech-okenka-392018.jpg";
import memoryImg from "../../assets/accessories/pexels-pixabay-33278.jpg";
import caseImg from "../../assets/accessories/pexels-burst-374140.jpg";
import gsap from 'gsap';
class Accessories extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
        // use the node ref to create the animation
        gsap.from('#battery', {duration: 2, delay: 0, y: -50, opacity: 0});
        gsap.from('#audio', {duration: 2, delay: 0.5, y: -50, opacity: 0});
        gsap.from('#pc', {duration: 2, delay: 1, y: -50, opacity: 0});
        gsap.from('#memory', {duration: 2, delay: 1.5, y: -50, opacity: 0});
        gsap.from('#case', {duration: 2, delay: 2, y: -50, opacity: 0});
    }
        
      render(){
        return (
            <Grid container className="h100 center service-card-grid">
            <Grid item sm={12} className="h100 p5">
                <div id="battery">
                    <SinglePhotoCard showPhoto={false} title="ThirdTab.individualOrderLabel" header="ThirdTab.individualOrderLabel" content="ThirdTab.individualOrderDesc" />
                </div>
                </Grid>
                <Grid item sm={6} className="h100 p5">
                <div id="battery">
                    <SinglePhotoCard showPhoto={true} img={batteryImg} title="ThirdTab.batteryLabel" header="ThirdTab.batteryLabel" content="ThirdTab.batteryDesc" />
                </div>
                </Grid>
                <Grid item sm={6} className="h100 p5">
                <div id="audio">
                    <SinglePhotoCard showPhoto={true} img={audioImg} title="ThirdTab.audioLabel" header="ThirdTab.audioLabel" content="ThirdTab.audioDesc" />
                </div>
                </Grid>
                <Grid item sm={6} className="h100 p5">
                <div id="pc">
                    <SinglePhotoCard showPhoto={true} img={pcImg} title="ThirdTab.pcPeripheralsLabel" header="ThirdTab.pcPeripheralsLabel" content="ThirdTab.pcPeripheralsDesc" />
                </div>
                </Grid>
                <Grid item sm={6} className="h100 p5">
                <div id="memory">
                    <SinglePhotoCard showPhoto={true} img={memoryImg} title="ThirdTab.memoryLabel" header="ThirdTab.memoryLabel" content="ThirdTab.memoryDesc" />
                </div>
                </Grid>
                <Grid item sm={6} className="h100 p5">
                <div id="case">
                    <SinglePhotoCard showPhoto={true} img={caseImg} title="ThirdTab.caseLabel" header="ThirdTab.caseLabel" content="ThirdTab.caseDesc" icon={<AndroidIcon />} />
                </div>
                </Grid>
            </Grid>
        );
      };
}

export default withRouter(Accessories)