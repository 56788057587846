 import React, { useState } from 'react';
 import {
    BrowserRouter as Router,
    Switch,
    Route, useHistory 
 } from "react-router-dom";
import {RoutesCollection} from '../utils/const.js';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FlagPicker from './FlagPicker/FlagPicker.js';
import AnimatedLogo from './AnimatedLogo/AnimatedLogo.js';
import { useTranslation } from 'react-i18next';
import SnackbarCookiesInfo from './SnackbarCookiesInfo';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SocialMediaButtons from './SocialMediaButtons.js';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
  }));


  class AppTemplate extends React.Component {
    render() { return (
        <Router>
            <TopBar />
            <RoutingOutlet />
            <CookiesInfo />
            <SocialMediaButtons />
        </Router>
    );
    }
 }

 function CookiesInfo()
 {
    const { t } = useTranslation();
     return (
        <SnackbarCookiesInfo description={t('CookiesToastr.desc')}/>
     );
 }

 function TopBar() {
    const classes = useStyles();
    const [drawerState, setDrawerState] = useState(false);
    const { i18n } = useTranslation();
    const history = useHistory();
    const { t } = useTranslation();
    function handleClick(path) {
        if(path) {
            history.push(path);
        }
        setDrawerState(false);
    }
    return (
        <AppBar position="static" className="app-toolbar-container">
        <Toolbar className="app-toolbar">
        {
           <IconButton className="drawer-button-open"  edge="start" color="inherit" aria-label="menu" onClick={() => { setDrawerState(true); }}>
                <MenuIcon />
            </IconButton>
        }
            <AnimatedLogo />
            <div className="flag-select-container" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                <FlagPicker className="flag" color="inherit" parentCallback={(flag) => {i18n.changeLanguage(flag)}} ></FlagPicker>
            </div>
        </Toolbar>
        <Drawer open={drawerState} anchor={'left'} onClose={() => { handleClick() }} >
            <div className="drawer-buttons-container">
                <div>
                    <Button className="drawer-button-close" variant="contained" color="default" startIcon={<CloseIcon />} onClick={() => { handleClick() } }></Button>
                    <h3 className="drawer-menu-text">MENU</h3>
                </div>
            <hr />
            {RoutesCollection.map(function(object, index){
                const btnLabel = t(object.btnLabel);
                return (
                    (object.inclInNav) && 
                    <div>
                        <Button className="drawer-button" variant="contained" color="default" startIcon={object.icon} key={index} onClick={() => { handleClick(object.path) } }>
                            {btnLabel}
                        </Button>
                        <hr />
                    </div>);

                })
            }
        </div>
        <SocialMediaButtons />
        </Drawer>
    </AppBar>
    );
 }

function RoutingOutlet() {
    return (
        <Switch>
            {RoutesCollection.map(function(object, index){
                return ( <Route path={object.path} exact={object.exact} key={index} render={object.component} /> );
            })}
        </Switch>
    );
}

 export default (AppTemplate)