import React from 'react';
import AppTemplate from './components/AppTemplate';
import './App.css';

function App() {
return (
    <AppTemplate />
  );
}
  
export default App;
