import img1 from './img1.jpg';
import img2 from './img2.jpg';
import img3 from './img3.jpg';
import img4 from './img4.jpg';
import img5 from './img5.jpg';
import img6 from './img6.jpg';
import img7 from './img7.jpg';
import img8 from './img8.jpg';
import img9 from './img9.jpg';
import img10 from './img10.jpg';
import img11 from './img11.jpg';

const images = [
    {
        original: img1,
        thumbnail: img1,
    },
    {
        original: img2,
        thumbnail: img2,
    },
    {
        original: img3,
        thumbnail: img3,
    },
    {
        original: img4,
        thumbnail: img4,
    },
    {
        original: img5,
        thumbnail: img5,
    },
    {
        original: img6,
        thumbnail: img6,
    },
    {
        original: img7,
        thumbnail: img7,
    },
    {
        original: img8,
        thumbnail: img8,
    },
    {
        original: img9,
        thumbnail: img9,
    },
    {
        original: img10,
        thumbnail: img10,
    },
    {
        original: img11,
        thumbnail: img11,
    }
];

export default images;