import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

function SingleCard(props) {
        const { t } = useTranslation();
        return (   
            <div className="h100 center">  
            <Card variant="outlined" className="service-card h100">
            <CardContent className="h100 service-card-content center">
              <Typography className="center">
                {props.icon}
              </Typography>
              <Typography className="center">
                {t(props.content)}
              </Typography>
            </CardContent>
          </Card>
        </div> 
        );
}

export default withTranslation("translate")(SingleCard);