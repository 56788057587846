import Home from '../components/Home/Home.js';
import Service from '../components/Service/Service.js';
import Accessories from '../components/Accessories/Accessories.js';
import Gallery from '../components/Gallery/Gallery.js';
import Contact from '../components/Contact/Contact.js';
import RouteData from './classes.js';
import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const RoutesCollection = [
    new RouteData(true, <HomeIcon />, 'FirstTab.label', '/', ((props) => <Home {...props} />), true),
    new RouteData(true, <BuildIcon />, 'SecondTab.label', '/service', ((props) => <Service {...props} />), false),
    new RouteData(true, <ImportantDevicesIcon />, 'ThirdTab.label', '/accessories', ((props) => <Accessories {...props} />), false),
    new RouteData(true, <PhotoCameraIcon />, 'FourthTab.label', '/gallery', ((props) => <Gallery {...props} />), false),
    new RouteData(true, <MailOutlineIcon />, 'FifthTab.label', '/contact', ((props) => <Contact {...props} />), false),
    new RouteData(false, null, null, '**', ((props) => <Home {...props} />), false),//<-- 404 error page LAST!!!
]

export default RoutesCollection;