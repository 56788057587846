import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import us from "./assets/i18n/us.js";
import pl from "./assets/i18n/pl.js";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        us: us,
        pl: pl
      },
      fallbackLng: "pl",
      debug: false,
    
      // have a common namespace used around the full app
      ns: ["translations"],
      defaultNS: "translations",
    
      keySeparator: false, // we use content as keys
    
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
      },
    
      react: {
        wait: true
      }
  });

  export default i18n;