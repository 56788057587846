import * as React from 'react';
import { Map, Marker } from "pigeon-maps"

function MapPigeon() {
  const y = 19.074817184610758;
  const x = 50.32376180079193;

  return (
    <div id="map" className="map-padding"> 
    <Map height={300} defaultCenter={[x, y]} defaultZoom={16}>
      <Marker width={50} anchor={[x, y]} />
    </Map>
    </div>
  )
}

export default (MapPigeon);