import React, { Component } from 'react'
import logoPart1 from '../../assets/logo/TELMI_Poziom_Biel-Kolor_animation1.svg'; 
import logoPart2 from '../../assets/logo/TELMI_Poziom_Biel-Kolor_animation2.svg'; 
import gsap from 'gsap';
export default class AnimatedLogo extends Component {
    constructor(props){
      super(props);
      // reference to the DOM node
      this.gearElement = null;
      // reference to the animation
      this.myTween = null;
    }
  
    componentDidMount(){
      // use the node ref to create the animation
      this.myTween = gsap.from(this.gearElement, {duration: 10, rotation: -360, ease: 'linear', repeat: -1});
    }
  
    render(){
      return (<div className="logo-container" >
        <img className="logo" src={logoPart1} alt="TELMI"/>
        <img ref={img => this.gearElement = img} className="gear" src={logoPart2} alt="TELMI" />
    </div>);
    }
  }