import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
const useStyles = makeStyles({
  media: {
    height: 350,
  },
});
function SinglePhotoCard(props) {

        const classes = useStyles();
        const { t } = useTranslation();
        return (   
            <div className="h100 center">  
            <Card variant="outlined" className="service-card h100">
            {(props.showPhoto) && <CardMedia image={props.img} title={t(props.title)} className={classes.media} />}
            <CardContent className="h100 service-card-content center">
              <Typography className="center" variant="h5" component="h2">
                {t(props.header)}
              </Typography>
              <Typography className="center p10">
                {t(props.content)}
              </Typography>
            </CardContent>
          </Card>
        </div> 
        );
}

export default withTranslation("translate")(SinglePhotoCard);