import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import folderImages from "../../assets/gallery/galleryList.js";
import ImageGallery from 'react-image-gallery';

class Gallery extends Component {


    constructor(props){
        super(props);
    }

    componentDidMount(){
    }

    render(){
        return (
            <div className="gallery">
                <ImageGallery items={folderImages} thumbnailPosition="top" showPlayButton={false} />
            </div>
            );
        };
}

export default withRouter(Gallery)