import React, { Component } from 'react'
import SingleCard from './SingleCard.js';
import {withRouter} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import AndroidIcon from '@material-ui/icons/Android';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import SecurityIcon from '@material-ui/icons/Security';
import SpeakerIcon from '@material-ui/icons/Speaker';
import UsbIcon from '@material-ui/icons/Usb';
import ScreenLockPortraitIcon from '@material-ui/icons/ScreenLockPortrait';
import gsap from 'gsap';

class Service extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
        // use the node ref to create the animation
        gsap.from('#software', {duration: 2, delay: 0, y: -50, opacity: 0});
        gsap.from('#screen', {duration: 2, delay: 0.5, y: -50, opacity: 0});
        gsap.from('#shield', {duration: 2, delay: 1, y: -50, opacity: 0});
        gsap.from('#audio', {duration: 2, delay: 1.5, y: -50, opacity: 0});
        gsap.from('#usb', {duration: 2, delay: 2, y: -50, opacity: 0});
        gsap.from('#simlock', {duration: 2, delay: 2.5, y: -50, opacity: 0});
    }
        
      render(){
        return (
            <Grid container className="h100 center service-card-grid">
                <Grid item sm={4} className="h100 p5">
                <div id="software">
                    <SingleCard content="SecondTab.software" icon={<AndroidIcon />} />
                </div>
                </Grid>
                <Grid item sm={4} className="h100 p5">
                <div id="screen">
                    <SingleCard content="SecondTab.screen" icon={<PhonelinkEraseIcon />} />
                </div>
                </Grid>
                <Grid item sm={4} className="h100 p5">
                <div id="shield">
                    <SingleCard content="SecondTab.shield" icon={<SecurityIcon />} />
                </div>
                </Grid>
                <Grid item sm={4} className="h100 p5">
                <div id="audio">
                    <SingleCard content="SecondTab.audio" icon={<SpeakerIcon />} />
                </div>
                </Grid>
                <Grid item sm={4} className="h100 p5">
                <div id="usb">
                    <SingleCard content="SecondTab.usb" icon={<UsbIcon />} />
                </div>
                </Grid>
                <Grid item sm={4} className="h100 p5">
                <div id="simlock">
                    <SingleCard content="SecondTab.simlock" icon={<ScreenLockPortraitIcon />}/>
                </div>
                </Grid>
            </Grid>
        );
      };
}

export default withRouter(Service)