import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';

const FlagPicker = ({parentCallback}) => {
    const [selected, setSelected] = useState('PL');
    return (
        <ReactFlagsSelect
            className="flag-select"
            countries={["US", "PL"]}
            selected={selected}
            onSelect={code => {
                setSelected(code);
                parentCallback(code.toLowerCase());
            }}
            showSelectedLabel={false}
            placeholder=""
            showSecondarySelectedLabel={false}
            showOptionLabel={false}
            showSecondaryOptionLabel={false}
        />
    )
}
 
export default (FlagPicker);