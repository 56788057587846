export class RouteData {
    constructor(inclInNav, icon, btnLabel, path, component, exact) {
        this.inclInNav = inclInNav;
        this.icon = icon;
        this.btnLabel = btnLabel;
        this.path = path;
        this.component = component;
        this.exact = exact;
    }
}

export default (RouteData);
