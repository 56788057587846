import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
function ContactButton() {
        const { t } = useTranslation();
        const history = useHistory();
        return (      
        <div className="m5">
            <Button className="contact-button" onClick={() => { history.push('/contact');; } }>
                {t('FirstTab.goToContact')}
            </Button>
        </div>);
}

export default withTranslation("translate")(ContactButton);