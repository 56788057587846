import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export default class SnackbarCookiesInfo extends Component {
    
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            openProp: true,
        };
      }

      handleClick() {
        this.state.openProp = false;
        this.forceUpdate();
      }
        
      render(){
        return (<Snackbar open={this.state.openProp} onClick={this.handleClick} >
        <Alert severity="info" >
            {this.props.description}
        </Alert>
    </Snackbar>);
      };
}