import img1 from './img1.jpg';
import img2 from './img2.jpg';
import img3 from './img3.jpg';
import img0 from './img0.jpg';
const images = [
    {
        original: img0,
    },
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    }
];

export default images;