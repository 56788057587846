import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import MapPigeon from './MapPigeon.js';
import WorkHours from './WorkHours.js';
import gsap from 'gsap';
class Contact extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        gsap.from('#contactCard', {duration: 3, opacity: 0});
        gsap.from('#workHoursCard', {duration: 3, opacity: 0});
        gsap.from('#map', {duration: 2, delay: 0, y: 50, opacity: 0});
    }

    render(){
        return (
            <div>
                <WorkHours />
                <MapPigeon />
            </div>
            );
        };
}

export default withRouter(Contact);