import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import gsap from 'gsap';
import TelmiDesc from './TelmiDesc.js';
import {withRouter} from 'react-router-dom'
import ImageGallery from 'react-image-gallery';
import homePageList from "../../assets/gallery/homePageList.js";
import ContactButton from './ContactButton.js';


class  Home extends Component {
    constructor(props){
        super(props);
      }

    componentDidMount(){
        // use the node ref to create the animation
        gsap.from('#firstParagraph', {duration: 2, delay: 0, x: -50, opacity: 0});
        gsap.from('#secondParagraph', {duration: 2, delay: 0.5, x: -50, opacity: 0});
        gsap.from('#thirdParagraph', {duration: 2, delay: 1, x: -50, opacity: 0});
        gsap.from('#fourthParagraph', {duration: 2, delay: 1.5, x: -50, opacity: 0});
        gsap.from('#fifthParagraph', {duration: 2, delay: 2, x: -50, opacity: 0});
        gsap.from('#sixthParagraph', {duration: 2, delay: 2.5, x: -50, opacity: 0});
    }
        
      render(){
        return (<Grid container>
            <Grid item sm={7}>
                <TelmiDesc />
            </Grid>
            <Grid item sm={5} >
                <ContactButton />
                <ImageGallery additionalClass="m5" items={homePageList} thumbnailPosition="top" showThumbnails={false} autoPlay={true} showNav={false} showFullscreenButton={false} showPlayButton={false}/>
            </Grid>
        </Grid>);
      };
}

export default withRouter(Home)