import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ContactData from '../../assets/contact.json';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import LocationOn from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

function  WorkHours() {
        const { t } = useTranslation();
        return (
            <div className="m5">
                <Grid container direction="row" justify="" alignItems="center">
                    <Grid item className="h100 w100 p5" sm={6}>
                    <Card id="contactCard" className="card">
                        <CardContent>
                            <table  className="center">
                                <tbody>
                                    <tr>
                                        <td><Phone /><span className="aligned-with-icon">{ splitPhoneNumber(ContactData.phoneNumber) }</span></td>
                                    </tr>
                                    <tr>
                                        <td><Email /><span className="aligned-with-icon">{ ContactData.email }</span></td>
                                    </tr>
                                    <tr>
                                        <td><LocationOn /><span className="aligned-with-icon">{ ContactData.address }</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item className="h100 w100 p5" sm={6}>
                    <Card id="workHoursCard" className="card">
                        <CardContent>
                            <table className="center">
                                <tbody>
                                    <tr>
                                        <td>{t('FifthTab.workTime')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('FifthTab.monday')} - {t('FifthTab.friday')}: <span>{ ContactData.week }</span></td>
                                    </tr>
                                    <tr>
                                        <td>{t('FifthTab.saturday')}: <span>{ ContactData.saturday }</span></td>
                                    </tr>
                                    <tr>
                                        <td>{t('FifthTab.sunday')}: <span>{ ContactData.sunday ? ContactData.sunday :  t('FifthTab.closed')}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>
            </div>
        );
        function splitPhoneNumber(value) {
            value = (value.match(/.{3}/g)).join().replaceAll(',', ' ');
            return value;
        }
}

export default withTranslation("translate")(WorkHours);