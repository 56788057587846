import React from 'react';
import { withTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ContactData from '../assets/contact.json';

function SocialMediaButtons()
{
   return (
       <div className="social-media">
           <Fab className="fb-btn" onClick={() => {window.location.href = ContactData.facebook;}}>
               <FacebookIcon />
           </Fab>
           <Fab className="insta-btn" onClick={() => {window.location.href = ContactData.instagram;}}>
               <InstagramIcon />
           </Fab>
           <Fab className="whatsapp-btn" onClick={() => {
               window.location.href = 'https://api.whatsapp.com/send?phone=' + ContactData.phoneNumber;
               }}>
               <WhatsAppIcon />
           </Fab>
       </div>
   );
}

export default withTranslation("translate")(SocialMediaButtons);