let us = {
  translations: {
    "CookiesToastr.header": "Cookies",
    "CookiesToastr.desc": "This site is using cookies, so it can provide the best experience. Click here, to close notification.",
    "FirstTab.label":"About us",
    "FirstTab.firstParagraph": "The TELMI company stands out in the market with its knowledge and experience",
    "FirstTab.secondParagraph": "Our employees are qualified people with many years of experience, related to customer service and electronic devices. Nevertheless, we constantly improve our competences and adapt to the conditions on the market.",
    "FirstTab.thirdParagraph": "Our business is based on an individual approach to each case, which directly translates into the satisfaction of our customers.",
    "FirstTab.fourthParagraph": "Our foundation is solidity and reliability. We want to give you the opportunity to use a professional service.",
    "FirstTab.fifthParagraph": "We have our own philosophy: we offer our knowledge, passion, commitment, we create a friendly atmosphere, because the most important thing for us is the satisfaction of our customers.",
    "FirstTab.sixthParagraph": "We invite you to our world of electronics enthusiasts.",
    "FirstTab.goToContact": "Click here, to contact us",
    "SecondTab.label":"Service",
    "SecondTab.content":"Here will be service",
    "SecondTab.software":"Software installation",
    "SecondTab.screen":"Screen replacement",
    "SecondTab.shield":"Protective glass",
    "SecondTab.audio":"Audio replacement",
    "SecondTab.usb":"USB replacement",
    "SecondTab.simlock":"Locks removal",
    "ThirdTab.content":"Here will be accessories",
    "ThirdTab.label":"Accessories",
    "ThirdTab.batteryLabel": "Power",
    "ThirdTab.audioLabel": "Audio",
    "ThirdTab.pcPeripheralsLabel": "PC peripherals",
    "ThirdTab.caseLabel": "Case",
    "ThirdTab.individualOrderLabel": "Individual order",
    "ThirdTab.memoryLabel": "Memory",
    "ThirdTab.batteryDesc": "We offer batteries for phones and laptops. USB power adapters and chargers.",
    "ThirdTab.audioDesc": "Speakers and headphones, both for phones and other devices.",
    "ThirdTab.pcPeripheralsDesc": "Our offer also includes keyboards, mice and other PC devices.",
    "ThirdTab.memoryDesc": "We sell memory cards, external drives, pendrives, etc.",
    "ThirdTab.caseDesc": "In our store you will also find a wide selection of cases that will be perfect for your phone and your needs.",
    "ThirdTab.individualOrderDesc": "If you are looking for something specific that we do not offer, please contact us. We will try to find and order what you need.",
    "FourthTab.label":"Gallery",
    "FourthTab.content":"Here will be  gallery",
    "FifthTab.label":"Contact",
    "FifthTab.closed": "Closed",
    "FifthTab.content":"Here will be contact data",
    "FifthTab.monday": "Monday",
    "FifthTab.tuesday": "Tuesday",
    "FifthTab.wednesday": "Wednesday",
    "FifthTab.thursday": "Thursday",
    "FifthTab.friday": "Friday",
    "FifthTab.saturday": "Saturday",
    "FifthTab.sunday": "Sunday",
    "FifthTab.workTime": "Work time"
}}
;

export default us;